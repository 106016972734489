import axios from 'axios'

import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./components/Home";
import Faq from "./components/Faq";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound";

class Root extends Component {
  async componentDidMount() {
    const { wallets, exchanges, downloads } = (await axios.get('/data.json')).data;
    this.setState({ wallets, exchanges, downloads })
  }

  constructor(props) {
    super(props);

    this.state = {
      wallets: [],
      exchanges: [],
      downloads: []
    };
  }

	render() {
		return (
			<BrowserRouter>
				<div>
					<NavBar />

					<Switch>
						<Route exact path="/" render={props => <Home {...props} wallets={this.state.wallets} exchanges={this.state.exchanges} downloads={this.state.downloads} />} />

						<Route path="/faq" render={props => <Faq {...props} />} />

						<Route component={NotFound} />
					</Switch>

					<Footer exchanges={this.state.exchanges} />
				</div>
			</BrowserRouter>
		);
	}
}

ReactDOM.render(<Root />, document.getElementById("root"));
