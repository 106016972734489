import _ from 'lodash'

import React, { Component } from "react";
import Particles from "react-particles-js";

import Wallet from "./partials/Wallet";
import Exchange from "./partials/Exchange";
import Downloadable from "./partials/Downloadable";

class Home extends Component {
  renderWallets() {
    if (this.props.wallets.length === 0) {
      return
    }

    const rows = _.chunk(this.props.wallets, 4)
    return rows.map((row, index) => {
      return (
        <div className="row" key={index}>{
          row.map((col, index) => {
            return(
              <Wallet
                title={col.name}
                url={col.url}
                image={col.image}
                type={col.type}
                width="3"
                key={index}
              />
            )
          })
        }</div>
      )
    })
  }

  renderExchanges() {
    if (this.props.exchanges.length === 0) {
      return
    }

    const rows = _.chunk(this.props.exchanges, 4)
    return rows.map((row, index) => {
      return (
        <div className="row" key={index}>{
          row.map((col, index) => {
            return (
              <Exchange
                title={col.name}
                url={col.url}
                image={col.image}
                type={col.type}
                width="3"
                key={index}
              />
            )
          })
        }</div>
      )
    })
  }

  renderDownloads() {
    if (this.props.downloads.length === 0) {
      return
    }

    const rows = _.chunk(this.props.downloads, 3)
    return rows.map((row, index) => {
      return (
        <div className="row" key={index}>{
          row.map((col, index) => {
            return (
              <Downloadable
                title={col.title}
                desc={col.desc}
                image={col.image}
                url={col.url}
                key={index}
              />
            )
          })
        }</div>
      )
    })
  }

	render() {
		return (
			<div className="homepage wrapper">
				<div className="container-fluid introduction">
					<Particles
						className="particles"
						params={{
							particles: {
								number: {
									value: 50
								},
								shape: {
									type: "edge",
									stroke: {
										width: 10,
										color: "#ffc057"
									}
								}
							}
						}}
					/>

					<div className="container">
						<div className="row">
							<div className="col-md-6 text">
								<h1>
									FASTER AND DECENTRALIZED: THE BITCOIN ALTERNATIVE FOR
									EVERYONE.
								</h1>
								<p>
									With transaction speeds more than 10x faster than Bitcoin and
									subpar alternatives, Bitcoin Fast is the invaluable payment
									system for everyone. Join our community today and start
									staking BCF for +25% annually with frequent rewards!
								</p>
								<div className="cta blocktime">LEARN MORE</div>
							</div>

							<div className="col-md-6 image">
								<div className="img-wrapper">
									<img className="samsung" src="/samsung.png" alt="samsung" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container-fluid blocktime inverted" id="blocktime">
					<div className="container">
						<div className="row">
							<div className="col-sm-6 large_icon_wrapper">
								<div className="large_icon missile">
									<svg
										version="1.1"
										id="Layer_1"
										xmlnsXlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										viewBox="0 0 32 32"
										style={{ enableBackground: "new 0 0 32 32" }}
										xmlSpace="preserve"
									>
										<circle className="st0" cx={16} cy={16} r={16} />
										<path
											className="st1"
											d="M31.9 14.2L24.7 7l-2 2-7.4 7.4-4.2 4.2-3.4 3.2h.1l-1.7 1.7 6 6c1.2.3 2.5.5 3.8.5 8.8 0 16-7.2 16-16 .1-.6 0-1.2 0-1.8z"
										/>
										<path
											className="st2"
											d="M18.9 8.1l-5.8 5.8H9.6l-3.5 4.6s3.7-1 5.8-.5l-5.8 7.5 7.6-5.9c1.1 2.4-.7 5.9-.7 5.9l4.6-3.5v-3.5l5.8-5.8L24.7 7l-5.8 1.1z"
										/>
									</svg>
								</div>
							</div>

							<div className="col-sm-6 text">
								<h1>60 SECONDS BLOCKTIME</h1>
								<p>
									It generally takes hours for a Bitcoin transaction to get
									confirmed, this presents major problems for micro-transactions
									as well as it prevents you from making deposits to exchanges
									in time for price or arbitrage opportunities.
								</p>
								<p>
									This is why BitcoinFast exists, to save you from missing out
									on these golden opportunities and facilitate
									micro-transactions.
								</p>
								<p>
									<b>Checkout our:</b>
								</p>
								<div className="buttons">
									<a href="http://www.ispace.co.uk/coindetails/?coin=bcf&id=35">
										<div className="cta mr-2">Mining Pool</div>
									</a>
									<a href="http://bcf.altcoinwarz.com:3003/">
										<div className="cta">Block Explorer</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container-fluid decentralized" id="decentralized">
					<div className="container">
						<div className="row">
							<div className="col-sm-6 text">
								<h1>DECENTRALIZED</h1>
								<p>
									Because of its decentralized nature, BitcoinFast is a highly
									secure and resilient payment system without the requirement of
									a goverment or military protection.
								</p>
								<p>
									This also means BitcoinFast has no single point of failure and
									attempts of attacking the system become much more difficult
								</p>
								<p>
									<b>Join our discussion on:</b>
								</p>
								<div className="buttons">
									<a href="https://discord.gg/nqEv9RT">
										<div className="cta">Discord</div>
									</a>
									<a href="https://t.me/BCFast">
										<div className="cta ml-2 mt-2">Telegram</div>
									</a>
									<a href="https://bitcointalk.org/index.php?topic=1982137">
										<div className="cta ml-2 mt-2">BitcoinTalk</div>
									</a>
									<a href="https://www.cryptopia.co.nz/Forum/Thread/1917?postId=9551">
										<div className="cta ml-2 mt-2">Cryptopia</div>
									</a>
								</div>
							</div>

							<div className="col-sm-6 large_icon_wrapper">
								<div className="large_icon decentralized">
									<svg
										version="1.1"
										id="Layer_1"
										xmlnsXlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										viewBox="0 0 32 32"
										style={{ enableBackground: "new 0 0 32 32" }}
										xmlSpace="preserve"
									>
										<circle className="st0" cx={16} cy={16} r={16} />
										<path
											className="st2"
											d="M24.6 20.4h-.3v-3.9c0-1.2-.9-2.1-2.1-2.1H17V12h.3c.5 0 .9-.4.9-.9V8c0-.5-.4-.9-.9-.9h-3c-.5 0-.9.4-.9.9v3c0 .5.4.9.9.9h.3v2.4H9.5c-1.2 0-2.1.9-2.1 2.1v3.9h-.3c-.5 0-.9.4-.9.9v3c0 .5.4.9.9.9h3c.5 0 .9-.4.9-.9v-3c0-.5-.4-.9-.9-.9h-.3v-3.6h4.8v3.6h-.3c-.5 0-.9.4-.9.9v3c0 .5.4.9.9.9h3c.5 0 .9-.4.9-.9v-3c0-.5-.4-.9-.9-.9H17v-3.6h4.8v3.6h-.3c-.5 0-.9.4-.9.9v3c0 .5.4.9.9.9h3c.5 0 .9-.4.9-.9v-3c.1-.4-.3-.8-.8-.8zM9.8 24H7.4v-2.4h2.4V24zm7.2 0h-2.4v-2.4H17V24zm-2.4-13.3V8.3H17v2.4h-2.4zM24.3 24h-2.4v-2.4h2.4V24z"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container-fluid specifications" id="specifications">
					<div className="container">
						<div className="row">
							<div className="col-sm-6 large_icon_wrapper">
								<div className="large_icon specifications">
									<svg
										id="cogs"
										version="1.1"
										xmlnsXlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										viewBox="0 0 32 32"
										style={{ enableBackground: "new 0 0 32 32" }}
										xmlSpace="preserve"
									>
										<circle className="st0" cx={16} cy={16} r={16} />
										<path
											className="st1"
											d="M31.9 14.4v-.2l-4.5-4.5L24.7 7l-2 2h-7.4v7.4l-4.2 4.2H7.7v3.2h.1l-1.7 1.7 6 6c.3.1.6.1.8.2h.1c.1 0 .3 0 .4.1.2 0 .4.1.5.1h.1c.6.1 1.2.1 1.8.1h.1c8.8 0 16-7.2 16-16 .1-.6.1-1.1 0-1.6z"
										/>
										<path
											id="_x3C_Compound_Path2_x3E_"
											className="st2"
											d="M14.7 22.9v-1.4l-1.5-.3c-.2-.2-.3-.4-.4-.7l.9-1.3-1-1-1.3.9c-.2-.1-.5-.2-.7-.3l-.3-1.5H9.1l-.3 1.5c-.3.1-.5.2-.7.3l-1.3-.9-1 1 .9 1.3c-.1.2-.2.5-.3.7l-1.5.3v1.4l1.5.3c.1.3.2.5.3.7l-.9 1.3 1 1 1.3-.9c.2.1.5.2.7.3l.3 1.5h1.4l.3-1.5c.3-.1.5-.2.7-.3l1.3.9 1-1-.9-1.3c.1-.2.2-.5.3-.7l1.5-.3zm-4.9.7c-.8 0-1.4-.6-1.4-1.4s.6-1.4 1.4-1.4 1.4.6 1.4 1.4-.7 1.4-1.4 1.4z"
										/>
										<path
											id="_x3C_Compound_Path1_x3E_"
											className="st2"
											d="M27.1 13.2v-1.4l-1.5-.3c0-.2-.1-.3-.1-.5l1.2-.8-.5-1.2-1.5.3c-.1-.1-.2-.3-.3-.4l.8-1.2-1-1-1.2.8c-.1-.1-.3-.2-.4-.3l.4-1.4-1.3-.5-.8 1.2c-.2 0-.3-.1-.5-.1l-.3-1.5h-1.4l-.3 1.5c-.2 0-.3.1-.5.1l-.8-1.2-1.3.5.3 1.5c-.1.1-.3.2-.4.3l-1.2-.8-1 1 .8 1.2c-.1.1-.2.3-.3.4L12.7 9l-.5 1.3 1.2.8c0 .2-.1.3-.1.5l-1.5.3v1.4l1.5.3c0 .2.1.3.1.5l-1.2.8.5 1.3 1.5-.3c.1.1.2.3.3.4l-.8 1.2 1 1 1.2-.8c.1.1.3.2.4.3l-.3 1.5 1.3.5.8-1.2c.2 0 .3.1.5.1l.3 1.5h1.4l.3-1.5c.2 0 .3-.1.5-.1l.8 1.2 1.3-.5-.3-1.5c.1-.1.3-.2.4-.3l1.2.8 1-1-.8-1.2c.1-.1.2-.3.3-.4l1.5.3.5-1.3-1.5-.9c0-.2.1-.3.1-.5l1.5-.3zm-7.6 2.3c-1.7 0-3-1.4-3-3s1.4-3 3-3c1.7 0 3 1.4 3 3s-1.4 3-3 3z"
										/>
									</svg>
								</div>
							</div>

							<div className="col-sm-6 text">
								<h1>SPECIFICATIONS</h1>
								<ul>
									<li>Hashing Algorithm: Scrypt POW/POS</li>
									<li>Genesis Date: Dec, 2 2014</li>
									<li>Max supply: 2.5 Billion</li>
									<li>POS Reward: 25% Annually</li>
									<li>Block time: 60 seconds</li>
									<li>POW Reward: 10.00000000</li>
									<li>Difficulty Retarget: 1</li>
									<li>Port: 8777</li>
									<li>RPC: 9344</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="container-fluid wallets inverted">
					<h1>WALLETS</h1>

					<div className="wallets_wrapper">
						{this.renderWallets()}
					</div>
				</div>

				<div className="container-fluid exchanges">
					<div className="container">
						<h1>EXCHANGES</h1>
						{this.renderExchanges()}
					</div>
				</div>

				<div className="container-fluid downloads">
					<h1>DOWNLOADS</h1>
					<div className="container">
            {this.renderDownloads()}
					</div>
				</div>

				<div className="container-fluid forms">
					<div className="container">
						<div className="row">
							<div className="col-sm-6 contact_us">
								<h1>CONTACT US</h1>
								<form
									method="POST"
									action="https://formspree.io/hieudoan2609@gmail.com"
								>
									<div className="input string required name">
										<input name="name" placeholder="Your name" />
									</div>
									<div className="input string required email">
										<input type="email" name="email" placeholder="Your email" />
									</div>
									<textarea
										name="message"
										placeholder="Your message"
										rows="6"
									/>
									<input
										type="submit"
										value="Send"
										id="mc-embedded-subscribe"
										className="button"
									/>
								</form>
							</div>

							<div className="col-sm-6 newsletter">
								<h1>NEWSLETTER</h1>
								<p>Hottest news about BitcoinFast, straight to your inbox.</p>
								<div id="mc_embed_signup">
									<form
										action="https://BitcoinFast.us17.list-manage.com/subscribe/post?u=99ade7f24cddb5651df9c9c29&amp;id=f3343cfc8b"
										method="post"
										id="mc-embedded-subscribe-form"
										name="mc-embedded-subscribe-form"
										className="validate"
										noValidate
									>
										<div id="mc_embed_signup_scroll">
											<div className="mc-field-group">
												<label htmlFor="mce-EMAIL">
													Email Address <span className="asterisk">*</span>
												</label>
												<input
													placeholder="Your Email"
													type="email"
													name="EMAIL"
													className="required email"
													id="mce-EMAIL"
												/>
											</div>
											<div className="mc-field-group">
												<label htmlFor="mce-FNAME">First Name </label>
												<input
													placeholder="First Name"
													type="text"
													name="FNAME"
													className=""
													id="mce-FNAME"
												/>
											</div>
											<div className="mc-field-group">
												<label htmlFor="mce-LNAME">Last Name </label>
												<input
													placeholder="Last Name"
													type="text"
													name="LNAME"
													className=""
													id="mce-LNAME"
												/>
											</div>
											<div id="mce-responses" className="clear">
												<div
													className="response"
													id="mce-error-response"
													style={{ display: "none" }}
												/>
												<div
													className="response"
													id="mce-success-response"
													style={{ display: "none" }}
												/>
											</div>
											<div
												style={{ position: "absolute", left: "-5000px" }}
												aria-hidden="true"
											>
												<input
													type="text"
													name="b_99ade7f24cddb5651df9c9c29_f3343cfc8b"
													tabIndex="-1"
												/>
											</div>
											<div className="clear">
												<input
													type="submit"
													value="Subscribe"
													name="subscribe"
													id="mc-embedded-subscribe"
													className="button"
												/>
											</div>
										</div>
									</form>
								</div>

								<div className="coins">
									<img src="/assets/newsletter.png" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Home;
