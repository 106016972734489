import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Download extends Component {
  render() {
    return (
      <div className="col-sm-4">
        <div className="download">
          <div className="download_wrapper">
            <div className="icon">
              <img src={this.props.image} alt={this.props.title} />
              <div className="background"></div>
            </div>
            <h2>
              {this.props.title}
            </h2>
            <p>
              {this.props.desc}
            </p>
            <a href={this.props.url} download><div className="cta">Download</div></a>
          </div>
        </div>
      </div>
    );
  }
}

Download.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default Download;
