import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Wallet extends Component {
  render() {
    let download = this.props.url
    ? <a href={this.props.url} download ><div className="cta">Download</div></a>
    : <p>Coming soon</p>

    if(this.props.title === "WEB WALLET"){
      download = <a href={this.props.url} download ><div className="cta">Visit now</div></a>
    }

    return (
      <div className={`col-md-${this.props.width} wallet ${this.props.type}`}>
        <div className="icon">
          <img src={this.props.image} alt={this.props.title} />
          <div className="background"></div>
        </div>
        <h2>
          {this.props.title}
        </h2>
        {download}
      </div>
    );
  }
}

Wallet.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default Wallet;
