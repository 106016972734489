import React, { Component } from 'react';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/accordion';
import Particles from 'react-particles-js';

class Faq extends Component {
  componentDidMount() {
    $("#accordion").accordion()
  }

  render() {
    return (
      <div className="faq wrapper">
        <div className="container-fluid introduction">
          <Particles
            className="particles"
            params={{
          		particles: {
          			number: {
                  value: 50,
                },
                shape: {
                  type: "edge",
                  stroke: {
                    width: 10,
                    color: "#ffc057",
                  }
                }
        			}
        		}}
          />

          <div className="container">
            <div className="row">

              <div className="col-sm-6 text">
                <h1>FREQUENTLY ASKED QUESTIONS</h1>
              </div>

              <div className="col-sm-6 image">
                <img src="/assets/newsletter.png" alt=""/>
              </div>

            </div>
          </div>
        </div>

        <div className="container-fluid questions">
          <div className="container">
            <div ref="accordion" id="accordion">
              <h3>What Type of Algorithm is used in BitcoinFast?</h3>
              <div>
                <p>
                  BitcoinFast algorithm is scrypt, proof of work (PoW) and proof of stake (PoS).
                </p>
                <p>
                  The reward for PoW is 10 BCF. PoS is set at 25%. These minted coins are shared equally amongst BCF Wallets.
                </p>
              </div>
              <h3>How Do you Stake Coins PoS in BitcoinFast?</h3>
              <div>
                <ol>
                  <li>Download a Windows Wallet from our website.</li>
                  <li>Fully sync your Wallet to the BCF Network by running your wallet and downloading the blockchain. (Automatic as soon as wallet is opened)</li>
                  <li>Buy some BCF from the Exchanges.</li>
                  <li>Withdraw BCF from the Exchanges to your Wallet.</li>
                  <li>Leave the coins in the wallet for 24 Hours. After 24 Hours the coins become eligible for Staking.</li>
                </ol>
                <ul>
                  <li>The wallet must be Unencrypted for it to allow Staking. (Found under Settings in the main Tool Bar. At Default BCF Wallet is Unencrypted).</li>
                </ul>
              </div>
              <h3>What is meant by the term Weight in PoS?</h3>
              <div>
                <p>
                The Weight of the Network is a figure given to the All the coins that are actively Staking on the entire BCF Network. The Coins in your Wallet that are availble for Staking also has a Weight. You have a good chance of Minting some new coins. However, if the Weight of your Coins is low then the chance of you Minting some new coins are remote. The Weight of the Coin is simply based on Coin Age. As a coin gets older as does the weight and your chance of Minting coins increases.
                </p>
              </div>
              <h3>Does my Wallet have to be open 24/7 to Receive my Stakes?</h3>
              <div>
                <p>
                It is true that to mine any coins your wallet must be open. If your wallet is closed it can not be part of the lottery. However the PoS rewards do NOT take into account the length of time a wallet is open. If you open your wallet for a few hours once a month you will receive roughly the same amount of coins as someone who is staking 24/7. The 24/7 Wallet will receive many more smaller amounts and the wallet open infrequently will receive less but larger amounts. The total will roughly be the same. The issue with doing this is that when a wallet is not open for a long period of time, then is opened again, the amount of potential Staking weight created by that wallet will be very high while the rewards catch up to meet the 365 day inflation calculation. The ideal situation is to leave the wallet open 24/7 so it provides a steady amount of PoS mining power on the network and acts as a node for gaining consensus.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Faq;
