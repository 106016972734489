import React, { Component } from "react";
import PropTypes from "prop-types";

class Exchange extends Component {
	render() {
		const url = this.props.url ? (
			<a href={this.props.url}>
				<div className="cta">Download</div>
			</a>
		) : (
			<p>Coming soon</p>
		);

		return (
			<div className={`col-md-${this.props.width} exchange ${this.props.type}`}>
				<div className="icon">
					<img src={this.props.image} alt={this.props.title} />
					<div className="background" />
				</div>
				<h2>{this.props.title}</h2>
				{url}
			</div>
		);
	}
}

Exchange.propTypes = {
	title: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	url: PropTypes.string
};

export default Exchange;
