import React, { Component } from 'react';
import Particles from 'react-particles-js';

class NotFound extends Component {
  render() {
    return (
      <div className="notfound wrapper">
        <div className="container-fluid introduction">
          <Particles
            className="particles"
            params={{
          		particles: {
          			number: {
                  value: 50,
                },
                shape: {
                  type: "edge",
                  stroke: {
                    width: 10,
                    color: "#ffc057",
                  }
                }
        			}
        		}}
          />

          <div className="container">
            <div className="row">

              <div className="col-sm-6 text">
                <h1>404 NOT FOUND</h1>
                <h5>Please make sure you have entered a valid URL.</h5>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
