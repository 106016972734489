import React, { Component } from "react";

import { Link } from "react-router-dom";

class NavBar extends Component {
	render() {
		return (
			<div>
				<div className="head">
					<div className="ticker-wrap">
						<div className="ticker">
							<div className="ticker__item" id="price" />
							<div className="ticker__item" id="pricebtc" />
							<div className="ticker__item" id="market_cap" />
							<div className="ticker__item" id="volume" />
							<div className="ticker__item" id="circulating_supply" />
							<div className="ticker__item">
								Powered by <span className="bold">CoinmarketCap</span>
							</div>
						</div>
					</div>

					<div className="my_custom_nav">
						<div className="container">
							<div className="content">
								<Link to="/">
									<div className="logo">
										<img src="/assets/bcf2-sm.png" alt="" />
									</div>
								</Link>

								<ul className="items">
									<li>
										<Link to="/">Home</Link>
									</li>
									<li>
										<a href="https://wallet.bitcoinfast.co/">Wallets</a>
									</li>
									<li>
										<a href="#" className="to downloads section">
											Downloads
										</a>
									</li>
									<li>
										<a href="#" className="to merchants section">
											Merchants
										</a>
									</li>
									<li>
										<Link to="/faq/">FAQ</Link>
									</li>
								</ul>
								<div className="hamburger">
									<svg
										id="i-menu"
										viewBox="0 0 32 32"
										width="32"
										height="32"
										fill="none"
										stroke="currentcolor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
									>
										<path d="M4 8 L28 8 M4 16 L28 16 M4 24 L28 24" />
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="head-padding" />
			</div>
		);
	}
}

export default NavBar;
