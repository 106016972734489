import React, { Component } from "react";

class Footer extends Component {
	getYear = () => {
		const today = new Date();
		return today.getFullYear();
	};

  renderExchange() {
    if (this.props.exchanges.length === 0) {
      return
    }

    const exchange = this.props.exchanges[0]

    return (
      <a
        href={exchange.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="cta mr-2">Trade now on {exchange.name}</div>
      </a>
    )
  }

	render() {
		return (
			<div className="container-fluid foot">
				<div className="container">
					<div className="row">
						<div className="col-sm-3" />
						<div className="col-sm-6">
							<svg
								className="globe"
								version="1.1"
								x="0px"
								y="0px"
								viewBox="0 0 32 32"
								style={{ enableBackground: "new 0 0 32 32" }}
							>
								<circle className="st0" cx={16} cy={16} r={16} fill="#E55744" />
								<path
									className="st1"
									fill="#A23D30"
									d="M23.1 8.9l-.4-.4C21 6.9 18.6 5.9 16 5.9 10.4 5.9 5.9 10.4 5.9 16c0 2.6 1 5 2.6 6.8l-.1.1 9.1 9.1c7.5-.7 13.5-6.7 14.3-14.2l-8.7-8.9z"
								/>
								<path
									className="st2"
									fill="#FFC057"
									d="M16 5.9C10.4 5.9 5.9 10.4 5.9 16S10.4 26.1 16 26.1 26.1 21.6 26.1 16 21.6 5.9 16 5.9zm0 18.9c-1.2 0-2.4-.3-3.5-.7l4.6-5.2c.1-.1.2-.3.2-.4v-1.9c0-.3-.3-.6-.6-.6-2.2 0-4.6-2.3-4.6-2.3-.1-.1-.3-.2-.4-.2H9.1c-.3 0-.6.3-.6.6v3.8c0 .2.1.5.3.6l2.2 1.1v3.7C8.7 21.6 7.2 19 7.2 16c0-1.4.3-2.6.8-3.8h2.3c.2 0 .3-.1.4-.2l2.5-2.5c.1-.1.2-.3.2-.4V7.6c.8-.2 1.6-.4 2.5-.4 1.4 0 2.7.3 3.9.9-.1.1-.2.1-.2.2-.7.7-1.1 1.7-1.1 2.7s.4 2 1.1 2.7c.7.7 1.7 1.1 2.7 1.1h.2c.3 1 .8 3.7-.2 7.3v.1c-1.6 1.6-3.8 2.6-6.3 2.6z"
								/>
							</svg>
						</div>
						<div className="col-sm-3" />
					</div>

					<div className="row">
						<div className="col-sm-3" />
						<div className="col-sm-6">
							<h1>GLOBAL TRANSACTION IN THE BLINK OF AN EYE.</h1>
							<div className="buttons">
								{this.renderExchange()}
							</div>
							<div className="group">
								<p>BitcoinFast Donation</p>
								<p>BDonate5SvjoyrhuGynqpeC9DgqfLQR9aJ</p>
							</div>
							<div className="group">
								<p>Bitcoin Donation</p>
								<p>18gN7vYLqc87iAvzq7e2DwZYh9mBvGgSxk</p>
							</div>
							<p>(Donations are used for exchange listing)</p>
							<div className="group social-medias">
								<a
									className="icon"
									href="https://www.facebook.com/bitcoinfast.crypto"
									target="_blank"
									rel="noopener noreferrer"
								>
									<svg
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										width="32"
										height="32"
										viewBox="0 0 32 32"
									>
										<title>facebook2</title>
										<path d="M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h13v-14h-4v-4h4v-2c0-3.306 2.694-6 6-6h4v4h-4c-1.1 0-2 0.9-2 2v2h6l-1 4h-5v14h9c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3z" />
									</svg>
								</a>
								<a
									className="icon"
									href="https://twitter.com/BitcoinFast_BCF"
									target="_blank"
									rel="noopener noreferrer"
								>
									<svg
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										width="32"
										height="32"
										viewBox="0 0 32 32"
									>
										<title>twitter</title>
										<path d="M32 7.075c-1.175 0.525-2.444 0.875-3.769 1.031 1.356-0.813 2.394-2.1 2.887-3.631-1.269 0.75-2.675 1.3-4.169 1.594-1.2-1.275-2.906-2.069-4.794-2.069-3.625 0-6.563 2.938-6.563 6.563 0 0.512 0.056 1.012 0.169 1.494-5.456-0.275-10.294-2.888-13.531-6.862-0.563 0.969-0.887 2.1-0.887 3.3 0 2.275 1.156 4.287 2.919 5.463-1.075-0.031-2.087-0.331-2.975-0.819 0 0.025 0 0.056 0 0.081 0 3.181 2.263 5.838 5.269 6.437-0.55 0.15-1.131 0.231-1.731 0.231-0.425 0-0.831-0.044-1.237-0.119 0.838 2.606 3.263 4.506 6.131 4.563-2.25 1.762-5.075 2.813-8.156 2.813-0.531 0-1.050-0.031-1.569-0.094 2.913 1.869 6.362 2.95 10.069 2.95 12.075 0 18.681-10.006 18.681-18.681 0-0.287-0.006-0.569-0.019-0.85 1.281-0.919 2.394-2.075 3.275-3.394z" />
									</svg>
								</a>
							</div>
						</div>
						<div className="col-sm-3" />
					</div>
				</div>
				<p className="copyright">BitcoinFast © {this.getYear()}</p>
			</div>
		);
	}
}

export default Footer;
